
















import { Component, Vue } from 'vue-property-decorator'

import EventStudyForm from '@/partials/forms/Events/StudyForm.vue'
import EventStudyReviewerTable from '@/partials/tables/Events/StudyReviewers/study.vue'

@Component({
  components: {
    EventStudyForm,
    EventStudyReviewerTable
  }
})
export default class EventStudyEdit extends Vue {

}
