


































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'
import isEmpty from 'lodash/isEmpty'
import { ValidationProvider } from 'vee-validate'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import EventUserDropdown from '@/partials/forms/Events/EventUserDropdown.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    EventUserDropdown,
    ValidationProvider,
    PasswordConfirmField,
    GenericModalDelete,
    GenericModalForm,
    TrashCan16,
    Ticket16,
    Edit16
  }
})
export default class EventStudyReviewersTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) eventStudyId!: string
  studyReviewer = []
  filteredData = []
  deleteModalIsOpened = false
  createModalIsOpened = false
  selectedStudyReviewer = {
    id: '',
    eventUser: {
      user: {
        name: ''
      }
    }
  }

  form: Record<string, any> = {
    eventStudyId: '',
    eventUserId: '',
    confirm: '',
    errors: {
      eventStudyId: '',
      eventUserId: '',
      confirm: ''
    }
  }

  created () {
    this.form.eventStudyId = this.eventStudyId
    this.fetch()
  }

  fetch () {
    axios.get(`/event/${this.eventId}/study/${this.eventStudyId}/reviewer`)
      .then(response => {
        this.studyReviewer = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  postCreateSubmit () {
    this.createModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'Avaliador adicionado ao trabalho com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  @Watch('createModalIsOpened')
  onCreateModalIsVisibleChange () {
    if (!this.createModalIsOpened) {
      this.form = {
        eventStudyId: this.eventStudyId,
        eventUserId: '',
        confirm: '',
        errors: {
          eventStudyId: '',
          eventUserId: '',
          confirm: ''
        }
      }
    }
  }

  postDeleteSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Avaliador foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  get deleteUrl () {
    return isEmpty(this.selectedStudyReviewer) ? '' : `/event/${this.eventId}/study/${this.eventStudyId}/reviewer/${this.selectedStudyReviewer.id}`
  }
}
